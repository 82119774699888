<template>
  <div>
    <CModal :show.sync="$store.state.showModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2"
      size="lg" color="dark">
      <template v-if="form" #header>
        <h6 class="modal-title font-weight-bold">
          تعديل المدينة: {{ form.name }}
        </h6>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <CRow v-if="form">
        <CCol col="12">
          <CCard>
            <CCardBody>
              <CForm>
                <CRow class="mt-3">
                  <CCol sm="12" md="9">
                    <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                      invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
                  </CCol>
                </CRow>

                <CRow class="mt-5">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3">المحافظة</label>
                      <CCol sm="9">
                        <v-select :options="states" v-model="form.state_id" :reduce="(state) => state.id" dir="rtl"
                          label="name" placeholder="اختار المحافظة">
                          <template> عذراً هذه المحافظة غير موجود </template>
                          <template v-if="$v.form.state_id.$anyError" #footer>
                            <div style="opacity: 0.8" class="text-danger mt-2">
                              يجب عليك اختيار المحافظة
                            </div>
                          </template>
                        </v-select>
                      </CCol>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-1">
                  <CCol sm="12" md="9">
                    <div role="group" class="form-group form-row">
                      <label class="col-form-label col-sm-3"> حالة المدينة </label>
                      <CCol sm="9">
                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input type="radio" name="city_id" class="form-check-input" value="1" v-model="form.status" />
                            نشط
                          </label>
                        </div>

                        <div role="group" class="form-check form-check-inline">
                          <label>
                            <input type="radio" name="city_id" class="form-check-input" value="0" v-model="form.status" />
                            متوقف
                          </label>
                        </div>
                      </CCol>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow v-if="formErrors">
        <CCol col="12">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>
        <CButton :disabled="loading" @click="update" type="button" color="success">
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else>
            <CIcon name="cil-save" /> حفظ
          </span>
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
export default {
  name: "Edit",
  props: ["form", "states"],
  data() {
    return {
      loading: false,
      formErrors: null,
      fileRecords: [],
      fileRecordsForUpload: [],
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2), max: maxLength(200) },
      status: { required },
      state_id: {},
    },
  },
  watch: {
    form(newValue, oldValue) {
      // This method will be called whenever 'form' changes
      console.log(`'form' changed from "${oldValue}" to "${newValue}"`);
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .put(`/cities/${this.form.id}`, {
          name: this.form.name,
          status: this.form.status,
          state_id: this.form.state_id,
        })
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    }
  },
};
</script>
